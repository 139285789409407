import { useDispatch, useSelector } from 'react-redux'
import { editPrintJob } from '../slices/printJob';

const FileUpload = (props) => {
    const printJob = useSelector((state) => state.printJob.printJob); 
    const dispatch = useDispatch();

    function handleFileUpload() {
        const file = document.getElementById("fileitem").files[0];
        dispatch(editPrintJob({fileName: file.name}));
        if (file) {
            const reader = new FileReader();
            reader.onloadend = function (e) {
                const base64Data = e.target.result;
                const pdfFile = base64Data.split(',')[1];
                dispatch(editPrintJob({pdfFile: base64Data}));

                fetch('/serverside/print_calc/calculate.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: base64Data
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    if (pdfFile && data.length != 0) {   
                        dispatch(editPrintJob({cmykArray: data}));
                        console.log(data);
                        console.log(printJob.pageNumber);
                        let subject = data[printJob.pageNumber-1];
                        if (!subject) {
                            subject = data[0]; // default to initial
                        }
                        let newCmykValues = {
                            black: (Math.round(subject[3]*100)/100).toFixed(2) + "%",
                            yellow: (Math.round(subject[2]*100)/100).toFixed(2) + "%",
                            magenta: (Math.round(subject[1]*100)/100).toFixed(2) + "%",
                            cyan: (Math.round(subject[0]*100)/100).toFixed(2) + "%",
                            spot: (Math.round(subject[4]*10000)/100).toFixed(2) + "%"
                        };
                        dispatch(editPrintJob({cmykValues: newCmykValues}));

                        let width = data[1000000000000]['width'];
                        let height = data[1000000000000]['height'];
                        // communicate these to color coverages
                        dispatch(editPrintJob({pageWidth: width}));
                        dispatch(editPrintJob({pageLength: height}));
                    }
                });
            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <div id="fileinputdiv" className="row">
            <div className="col s12 l12 m12">
                <div id="filediv" className="file-field input-field">
                    <input id="fileitem" onChange={handleFileUpload} type="file" data-request-url="/CostCalculator/CostCalculator/UploadFile" accept={printJob.calculatorMethod == "Upload PDF" ? "application/pdf" : ".csv"} />
                    <div className="file-path-wrapper"><input id="filedraginput" className="file-path validate" type="text" placeholder="Drag-Drop the file here [OR] click here to Browse and Upload" /></div>
                </div>
                <input id="FilePath" name="FilePath" type="hidden" value="" />
            </div>
        </div>
    )
}

export default FileUpload;