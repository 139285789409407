import FileUpload from '../components/FileUpload';
import SectionSettings from '../components/PostUploadCalculations/SectionSettings';
import ColorCoverages from '../components/PostUploadCalculations/ColorCoverages';
import ModelSelect from '../components/ModelSelect';
import Output from '../components/PostUploadCalculations/Output';
import NavBar from '../components/NavBar';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { editPrintJob } from '../slices/printJob';
  
const PrintCalculator = () => {
    const printJob = useSelector((state) => state.printJob.printJob); 
    const dispatch = useDispatch();

    const [pageNumber, setPageNumber] = useState(printJob.pageNumber);
    const [output, setOutput] = useState({});

    const getCorrectedYield = (item, effectiveCoverage) => {
        var profileYield = printJob.printerProfileData[item+'Yield'];
        return (profileYield / effectiveCoverage) * printJob.printerProfileData['CoverageBasePercent'];
    };
    // takes in something like "Toner.Black" or "ImageDrum.Yellow"
    // this needs to be moved to a store...
    const calculateCost = (correctedYield, item) => {
        if (printJob.printerProfileData[item+'Enable'] == false || typeof printJob.printerProfileData[item+'Enable'] == "undefined") return 0;

        // fixed cost items have neg coverage
        if (correctedYield < 0) {
            if (Math.max(printJob.pageWidth, printJob.pageLength) > printJob.printerProfileData['MaxPrintWidth']) {
                return 2*printJob.printerProfileData[item+'Cost']/printJob.printerProfileData[item+'Yield'];

            } else {
                return printJob.printerProfileData[item+'Cost']/printJob.printerProfileData[item+'Yield'];
            }
        }

        if (item.indexOf('Toner') != -1) {  
            return (printJob.printerProfileData[item+'Cost']/correctedYield)/100;// scale down 100
        }

        if (item.indexOf('ImageDrum') != -1) {
            var shorterDimension = Math.min(printJob.pageWidth, printJob.pageLength);
            var calculatedLengthRatio = shorterDimension / printJob.printerProfileData['CountBreakoverLength'] * 1.1;
            correctedYield = printJob.printerProfileData[item+'Yield'] / calculatedLengthRatio;
            return printJob.printerProfileData[item+'Cost']/correctedYield;
        }
    }

    const getMaxPageArea = () => {
        return printJob.printerProfileData['CoverageBaseSquareInches'];
    }

    const runCalculation = () => {
        let multiplier = 0;
        let total = 0;

        if (!printJob.pageArea) {
            alert("Please enter a page size");
            return;
        }

        const pageArea = getMaxPageArea();

        multiplier = printJob.pageArea / pageArea;

        let costObj = {};

        if (printJob.substrateCalculation === "CPLF") {
            const cplf = parseFloat(printJob.pageCost.replace('$', ''));
            const linearFeetPerPrint = 12/Number(printJob.longestDimension);
            const calculated = cplf / linearFeetPerPrint;
            costObj['Substrate Cost'] = calculated.toFixed(2);
            total += cplf * linearFeetPerPrint;
        } else {
            if (printJob.pageCost) {
                costObj['Page Cost'] = printJob.pageCost.replace('$', '');
                total += parseFloat(printJob.pageCost.replace('$', ''));
            } else {
                costObj['Page Cost'] = 0;
                total += 0;

            }
        }
        

        let checkArray = ['Toner.Black', 'Toner.Yellow', 'Toner.Magenta', 'Toner.Cyan', 'Toner.Spot', 'ImageDrum.Black', 'ImageDrum.Yellow', 'ImageDrum.Magenta', 'ImageDrum.Cyan', 'ImageDrum.Spot', 'Others.Fuser', 'Others.Belt', 'Others.WasteToner'];

        for (let i = 0; i < checkArray.length; i++) {
            let coverage = 0;
            var label = checkArray[i].replace('.', ' ');
            
            // CMY dont get calculated on grayscale
            if (!printJob.useGrayScale) {
                if (label.indexOf('Yellow') != -1) {
                    coverage = printJob.cmykArray[printJob.pageNumber-1][2];
                }
                if (label.indexOf('Magenta') != -1) {
                    coverage = printJob.cmykArray[printJob.pageNumber-1][1];
                }
                if (label.indexOf('Cyan') != -1) {
                    coverage = printJob.cmykArray[printJob.pageNumber-1][0];
                }
                if (label.indexOf('Spot') != -1) {
                    coverage = printJob.cmykArray[printJob.pageNumber-1][4];
                }
            } else {
                coverage = 0;
            }
            if (label.indexOf('Black') != -1) {
                coverage = printJob.cmykArray[printJob.pageNumber-1][3];
            }
            if (label.indexOf('Other') != -1) {
                coverage = -1;
            }
            coverage = Number(coverage);
            label = label.replace('Others ', '');
            
            // if label contains 'black'
            
            var effectiveCoverage = multiplier * coverage;
            var correctedYield = getCorrectedYield(checkArray[i], effectiveCoverage);

            costObj[label] = calculateCost(correctedYield, checkArray[i]);
            total += costObj[label];
        }
        costObj['Total'] = total;

        setOutput(costObj);
    }

    // keep in here
    const updatePageNumber = (value) => {
        if (value > printJob.cmykArray.length) {
            value = printJob.cmykArray.length;
        }
        if (value < 1) {
            value = 1;
        }
        
        setPageNumber(value);

        dispatch(editPrintJob({pageNumber: value}));
    }

    const resetUpload = (e) => {
        e.preventDefault();
        dispatch(editPrintJob({pdfFile: null, cmykArray: null, cmykValues: null}));

    }

    return (
        <>
            <NavBar/>
            <h4>&nbsp;</h4><h4 style={{height: "3px"}}>&nbsp;</h4>
            <h4 id="title" className="center">Cost Calculator</h4>
            <ModelSelect Page="PrintCalc"/>
            { printJob.calculatorMethod && (
            <>
                <div className="row"><button id="colorbtn" onClick={resetUpload} className="waves-effect waves-light btn left">Reset Form Upload</button></div>
                <div className="section">
                    <div id="inputheader" className="collapsible-header grey lighten-2">Print Preferences</div>
                    <div id="inputrow" className="row">
                    <div className="col s12 m6 l8">
                        <div className="col s12 m12 l12" id="filedsetdiv">
                        { (printJob.calculatorMethod === 'Upload PDF' || printJob.calculatorMethod == 'Upload CSV') && !printJob.cmykValues && (
                            <FileUpload/>
                        )}
                        { printJob.cmykValues && (
                            <>
                                <ColorCoverages />
                            </>

                        )}
                        { printJob.pdfFile &&  (
                            <SectionSettings />
                        )}
                        </div>
                    </div>
                    { printJob.pdfFile && (<>
                        <div className="col s12 m6 l4">
                            <label id="pdfpreviewlabel">PDF Preview:</label>
                            <embed className="pdfembed" src={printJob.pdfFile+"#page"+pageNumber} />
                            <div id="pagenumberinput" className="input-field col s12 l9 m12">
                                <input className="validate valid" data-val="true" data-val-number="The field PageNumber must be a number." id="numberprint_inline" name="PageNumber" type="text" onChange={(e) => {updatePageNumber(e.target.value);} } value={pageNumber} />
                                <label className="required active" id="pagenumberlabel" htmlFor="numberprint_inline">Page Number</label>
                            </div>
                        </div>
                        <div id="calculatediv" className="col s12 m12 l9">
                            <button id="calculatebtn" onClick={runCalculation} className="waves-effect waves-light btn left">Calculate</button>
                        </div>
                        </>
                    )}
                    </div>
                </div>
                <Output Output={output} />
            </>
        )}
    </>
    );
}

export default PrintCalculator;