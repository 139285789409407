import { createSlice } from '@reduxjs/toolkit';


export const printJobSlice = createSlice({
    name: 'printJob',
    initialState: {
        printJob: {pageNumber: 1, pageUnit: "in", useGrayScale: false, pageCost: "$0", pageCount: 1, substrateCalculation: "CPP", pageWidth: '8.5', pageUnit: "inch", calculatorMethod: "Upload PDF", pageLength: '11'}
    },
    reducers: {
        editPrintJob: (state, action) => {
            const newPrintJob = {...state.printJob};
            for (const key in action.payload) {
                newPrintJob[key] = action.payload[key];
            }
            state.printJob = newPrintJob
        }
    },
    extraReducers: (builder) => {
        builder.addCase('sessionSelections/editSelections', (state, action) => {
            const newPrintJob = {...state.printJob};
            for (const key in action.payload) {
                newPrintJob[key] = action.payload[key];
            }
            state.printJob = newPrintJob;
        });
    }
});

export const { editPrintJob } = printJobSlice.actions;
export default printJobSlice.reducer;